//React
import React from 'react'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../components/layout'
import PostLink from '../components/marketing/postLink'

//Icons
import { FaArrowRight } from 'react-icons/fa'

const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  const Posts = edges
    .filter(
      (edge) =>
        !!edge.node.frontmatter.date && edge.node.frontmatter.publish === 1
    ) // You can filter your posts based on some criteria
    .map((edge) => <PostLink key={edge.node.id} post={edge.node} />)
  return (
    <Layout>
      <Helmet
        title="Picohealth - Employee health screening resources"
        meta={[
          {
            name: 'description',
            content:
              'See articles and info about employee health screening and daily health checks for COVID-19, including how businesses can re-open and return to work faster',
          },
          {
            name: 'keywords',
            content:
              'Covid, Covid-19, health, health questionnaire, covid questions, return to work, pandemic, pandemic assessment questions, worker health question, workplace health questions, workplace health, company health, company safety',
          },
        ]}
      />
      <div class="gradient-callout-cousteau">
        <div class="container container-page container-about-sub">
          <div class="row">
            <div class="col-lg-8 d-flex flex-column justify-content-center text-center text-lg-left">
              <h1 class="display-2 font-weight-medium white-1">Resources</h1>
              <p class="display-4 white-4">
                Get the latest on how you can keep your business healthy and
                safe
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="container container-page container-features">
        <div class="row d-flex">
          <div class="col-lg-12">{Posts}</div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { path: { glob: "/resources/*" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            updated(formatString: "MMMM DD, YYYY")
            path
            title
            publish
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 400) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`

// export const pageQuery = graphql`
//   query {
//     homebg: file(
//       relativePath: { eq: "stock/unsplash/arturo-rey-5yP83RhaFGA-unsplash.jpg" }
//     ) {
//       childImageSharp {
//         fluid(quality: 100, maxWidth: 1920, maxHeight: 600, cropFocus: EAST) {
//           ...GatsbyImageSharpFluid_withWebp
//         }
//       }
//     }
//   }
// `

//React
import React from 'react'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Icons
import { FaArrowRight } from 'react-icons/fa'

const Snippet = ({ post }) => (
  <div class="post-item round-corners-top round-corners-bottom m-2">
    <Link to={post.frontmatter.path}>
      <Img
        className="rounded-corners"
        sizes={post.frontmatter.featuredImage.childImageSharp.sizes}
      />
    </Link>

    <Link
      className="post-text-link d-flex flex-column"
      to={post.frontmatter.path}
    >
      <span class="post-text-date pb-2">
        {post.frontmatter.date}{' '}
        {post.frontmatter.updated &&
        !post.frontmatter.updated.includes('Invalid') ? (
          <span class="post-text-date pb-2 ml-2 dkblue-1">
            Updated: {post.frontmatter.updated}
          </span>
        ) : null}
      </span>

      <div>
        <span class="post-text">{post.frontmatter.title}</span>
        <FaArrowRight size={18} class="black-4 ml-2" />
      </div>
    </Link>
  </div>
)
export default Snippet
